import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';
// import { Helmet } from 'react-helmet';

const Thyroid: React.FC = () => {
  const thyroidInfo = {
    title: "Thyroid Disorders",
    introduction: "Thyroid disorders affect millions of people worldwide. Our specialized care focuses on diagnosing and treating various thyroid conditions to restore hormonal balance and improve overall health.",
    imageSrc: "/images/thyroid-disorders.jpg",
    sections: [
      {
        title: "Common Thyroid Disorders",
        content: [
          "Hypothyroidism: Underactive thyroid gland",
          "Hyperthyroidism: Overactive thyroid gland",
          "Goiter: Enlarged thyroid gland",
          "Thyroid nodules: Abnormal growths in the thyroid",
          "Thyroid cancer: Malignant thyroid growths"
        ]
      },
      {
        title: "Symptoms",
        content: [
          "Fatigue and weakness",
          "Unexplained weight changes",
          "Mood swings or depression",
          "Hair loss or dry skin",
          "Changes in heart rate",
          "Difficulty regulating body temperature"
        ]
      },
      {
        title: "Diagnosis and Treatment",
        content: [
          "Blood tests to measure thyroid hormone levels",
          "Imaging studies such as ultrasound or thyroid scans",
          "Fine needle aspiration biopsy for suspicious nodules",
          "Hormone replacement therapy for hypothyroidism",
          "Anti-thyroid medications or radioactive iodine for hyperthyroidism",
          "Surgical intervention when necessary"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Comprehensive evaluation of thyroid function",
          "Personalized treatment plans",
          "Regular monitoring and adjustment of medications",
          "Patient education on thyroid health and self-care",
          "Coordination with endocrine surgeons for complex cases"
        ]
      }
    ]
  };

  return <ServiceArticle {...thyroidInfo} />
};

export default Thyroid;
