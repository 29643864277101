import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';

const Diabetes: React.FC = () => {
  const diabetesInfo = {
    title: "Diabetes Management",
    introduction: "Diabetes is a chronic condition that affects how your body processes glucose (sugar). Our comprehensive diabetes management program is designed to help you control your blood sugar levels and prevent complications.",
    imageSrc: "/images/diabetes-management.jpg",
    sections: [
      {
        title: "Types of Diabetes",
        content: [
          "Type 1 Diabetes: An autoimmune condition where the body doesn't produce insulin.",
          "Type 2 Diabetes: The most common form, where the body becomes resistant to insulin or doesn't produce enough.",
          "Gestational Diabetes: Occurs during pregnancy and usually resolves after delivery."
        ]
      },
      {
        title: "Symptoms",
        content: [
          "Increased thirst and frequent urination",
          "Unexplained weight loss",
          "Fatigue and irritability",
          "Blurred vision",
          "Slow-healing wounds"
        ]
      },
      {
        title: "Prevention and Management",
        content: [
          "Maintain a healthy diet rich in fiber and low in simple carbohydrates",
          "Regular physical activity",
          "Monitoring blood glucose levels",
          "Medication management, including insulin therapy when necessary",
          "Regular check-ups and screenings for complications"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Personalized treatment plans tailored to your specific needs",
          "Education on self-management techniques",
          "Access to the latest diabetes management technologies",
          "Coordination with other specialists for comprehensive care",
          "Support for lifestyle modifications to improve overall health"
        ]
      }
    ]
  };

  return <ServiceArticle {...diabetesInfo} />;
};

export default Diabetes;