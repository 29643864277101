import React from 'react';

interface TestimonialCardProps {
  name: string;
  testimonial: string;
  rating: number;
  avatar: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ name, testimonial, rating, avatar }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1 cursor-pointer">
    <div className="flex items-center mb-4">
      <img src={avatar} alt={name} className="w-12 h-12 rounded-full mr-4" />
      <div>
        <h3 className="font-semibold text-lg">{name}</h3>
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className={`w-4 h-4 ${i < rating ? 'text-yellow-400' : 'text-gray-300'} mr-1`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>
      </div>
    </div>
    <p className="text-gray-600 flex-grow">&ldquo;{testimonial}&rdquo;</p>
  </div>
  );
};

export const testimonials = [
  {
    name: "Jitendra Singh",
    testimonial: "My Father's diabetes was not coming under control by one of our neighbor's recommendation My father started his treatment from Dr Aggarwal. We are really happy with the treatment going so far. Thanks",
    rating: 5,
    avatar: "/images/avatar1.jpg",
  },
  {
    name: "Nikita Aheja",
    testimonial: "I would suggest to visit this Dr. , as he will never recommend any medicine without any reason, instead he will counsel you with positive vibes.",
    rating: 4,
    avatar: "/images/avatar2.jpg",
  },
  {
    name: "Nipun Chaudhary",
    testimonial: "He's the best one I have seen until now for my mom's diabetes problem. He's her doctor for the last 8 years now.",
    rating: 5,
    avatar: "/images/avatar3.jpg",
  },
  {
    name: "Ankit",
    testimonial: "I've been under the care of a specialist for my diabetes, and I'm impressed with their knowledge and compassionate approach. They explain treatment options clearly, involve me in decisions, and stay updated with the latest research. Highly recommended for anyone needing expert diabetes management.",
    rating: 5,
    avatar: "/images/avatar4.jpg"  
  }
];

const Testimonials: React.FC = () => {
  return (
    <section id='testimonials' className='w-full bg-blue-50 py-24'>
    <div className='container mx-auto px-4 sm:px-6 lg:px-8'>
      <div className="flex flex-col lg:flex-row items-start justify-between">
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <h2 className="text-3xl font-bold mb-6 text-center lg:text-left">Patient Testimonials</h2>
          <div className="relative mb-8">
            <div className="absolute inset-0 bg-blue-200 rounded-full opacity-20 filter blur-3xl transform -rotate-6"></div>
            <img
              src="/images/testimonials.jpg"
              alt="Dr. Ajay Aggarwal"
              className="relative z-10 w-64 h-64 object-cover rounded-full shadow-2xl mx-auto lg:mx-0"
            />
          </div>
        
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {testimonials.slice(0, 2).map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </div>

        <div className="lg:w-1/2 lg:pl-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            {testimonials.slice(2, 4).map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
          <div className="relative mt-16">
              <img 
                src="/images/ajag.jpg" 
                alt="Dr. Ajay Aggarwal with a patient" 
                className="rounded-lg shadow-xl w-full"
              />
              <div className="absolute -bottom-14 -left-4 bg-blue-600 text-white py-0 px-8 rounded-lg shadow-md lg:py-6">
                <p className="text-2xl font-semibold">Trusted by 50k+ Patients</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    
  );
};

export default Testimonials;
