// Header.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../index.css';

const Header: React.FC = () => {
  const [isServicesOpen, setIsServicesOpen] = useState<boolean | false>(false);
  const [isScrolled, setIsScrolled] = useState<boolean | false>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean | false>(false);

  const services = [
    { name: 'Diabetes Management', path: '/services/diabetes-management' },
    { name: 'Thyroid Disorders', path: '/services/thyroid-disorders' },
    { name: 'Hormonal Imbalances', path: '/services/hormonal-imbalance' },
    { name: 'Growth & Development Issues', path: '/services/growth-development' },
    { name: 'Osteoporosis & Bone Health', path: '/services/osteoporosis-bone' },
    { name: 'Infertility and Sexual Health', path: '/services/infertility-sexual-health' }
  ];

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
    setIsServicesOpen(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 bg-white text-gray-800 transition-all duration-300 p-6 ${isScrolled ? 'shadow-md py-2' : 'py-4'}`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-3">
            <img src='/images/logo.png' alt="Dr. Ajay Aggarwal" className={`transition-all duration-300 ${isScrolled ? 'h-[50px]' : 'h-[70px]'}`} />
          </Link>
          <nav className="hidden lg:flex items-center space-x-8">
            <Link to="/" className="header-link">Home</Link>
            <Link to="/about" className="header-link">About</Link>
            <div className="relative group">
              <button
                className="header-link flex items-center"
                onClick={() => setIsServicesOpen(!isServicesOpen)}
              >
                Services
                <svg className="ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              {isServicesOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical">
                    {services.map((service) => (
                      <Link
                        key={service.path}
                        to={service.path}
                        className="block px-4 py-4 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={handleLinkClick}
                      >
                        {service.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <Link to="/blog" className="header-link"> Blog </Link>
            <Link to="/contact" className="header-link">Contact</Link>
          </nav>
          <div className="hidden lg:block">
            <Link to="/contact" className="header-button">
              Schedule Appointment
            </Link>
          </div>
          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              className="text-gray-600 hover:text-blue-600 focus:outline-none"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden fixed inset-0 bg-white z-50 flex flex-col">
            <div className="p-4 pt-16">
              {/* Close button */}
              <button
                className="absolute top-4 right-4 text-gray-600 hover:text-blue-600 focus:outline-none"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <Link to="/" className="block py-2 header-link" onClick={handleLinkClick}>Home</Link>
              <Link to="/about" className="block py-2 header-link" onClick={handleLinkClick}>About</Link>
              <Link to="/services" className="block py-2 header-link" onClick={handleLinkClick}>Services</Link>
              <Link to="/blog" className="block py-2 header-link" onClick={handleLinkClick}>Blog</Link>
              <Link to="/contact" className="block py-2 header-link" onClick={handleLinkClick}>Contact</Link>
            </div>
            {/* Divider */}
            <div className="border-t border-gray-200 my-2"></div>
            {/* Centered button */}
            <div className="p-4 flex justify-center">
              <Link to="#appointment" className="block w-full text-center header-button" onClick={handleLinkClick}>
                Schedule Appointment
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;