
// src/pages/Growth.tsx

import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';

const Growth: React.FC = () => {
  const growthInfo = {
    title: "Growth and Development Issues",
    introduction: "Growth and development issues can significantly impact a child's life. Our specialized care focuses on identifying and addressing these concerns to support healthy growth and development.",
    imageSrc: "/images/growth-development.jpg",
    sections: [
      {
        title: "Common Growth and Development Issues",
        content: [
          "Short stature",
          "Delayed puberty",
          "Growth hormone deficiency",
          "Turner syndrome",
          "Precocious puberty",
          "Failure to thrive"
        ]
      },
      {
        title: "Signs and Symptoms",
        content: [
          "Height significantly below average for age",
          "Slow growth rate",
          "Delayed or absent puberty signs",
          "Early onset of puberty",
          "Disproportionate body growth",
          "Developmental delays"
        ]
      },
      {
        title: "Diagnosis and Treatment",
        content: [
          "Comprehensive physical examination",
          "Growth chart monitoring",
          "Bone age X-rays",
          "Hormonal blood tests",
          "Genetic testing when appropriate",
          "Growth hormone therapy",
          "Puberty-delaying or inducing treatments",
          "Nutritional interventions"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Individualized growth assessments",
          "Long-term growth monitoring",
          "Customized treatment plans",
          "Collaboration with pediatricians and other specialists",
          "Family-centered care and education",
          "Psychological support for children and families",
          "Access to clinical trials and latest treatments"
        ]
      }
    ]
  };

  return <ServiceArticle {...growthInfo} />;
};

export default Growth;