import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';

const Hormonal: React.FC = () => {
  const hormonalInfo = {
    title: "Hormonal Imbalances",
    introduction: "Hormonal imbalances can affect various aspects of your health and well-being. Our expert care focuses on identifying and treating these imbalances to restore your body's natural harmony.",
    imageSrc: "/images/hormonal-imbalances.jpg",
    sections: [
      {
        title: "Types of Hormonal Imbalances",
        content: [
          "Adrenal disorders (e.g., Addison's disease, Cushing's syndrome)",
          "Pituitary gland dysfunction",
          "Reproductive hormone imbalances (e.g., PCOS, low testosterone)",
          "Metabolic hormone disorders (e.g., insulin resistance)"
        ]
      },
      {
        title: "Common Symptoms",
        content: [
          "Unexplained weight gain or loss",
          "Fatigue and low energy",
          "Mood swings, anxiety, or depression",
          "Sleep disturbances",
          "Changes in appetite or metabolism",
          "Skin issues or hair loss",
          "Reproductive health problems"
        ]
      },
      {
        title: "Diagnosis and Treatment",
        content: [
          "Comprehensive hormonal panel blood tests",
          "Saliva or urine tests for specific hormones",
          "Imaging studies when necessary",
          "Hormone replacement therapy",
          "Lifestyle modifications (diet, exercise, stress management)",
          "Medications to address specific imbalances",
          "Bioidentical hormone treatments"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Thorough evaluation of hormonal health",
          "Customized treatment plans",
          "Integration of conventional and functional medicine approaches",
          "Regular monitoring and adjustment of treatments",
          "Patient education on hormonal health and balance",
          "Collaboration with other specialists for comprehensive care"
        ]
      }
    ]
  };

  return <ServiceArticle {...hormonalInfo} />;
};

export default Hormonal;
