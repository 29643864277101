import React, { useEffect, useState } from 'react';
import { FaCalendarAlt, FaUserMd, FaClinicMedical } from 'react-icons/fa';
import { useForm, ValidationError } from "@formspree/react";
import '../../index.css'

const BookAppointment: React.FC = () => {
  const [state, handleSubmit] = useForm('xjkbrezd');
  const [imageLoaded, setImageLoaded] = useState<boolean | false>(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    message: ''
  });

  useEffect(() => {
    if (state.succeeded) {
      const img = new Image();
      img.src = '/images/thankyou.png';
      img.onload = () => setImageLoaded(true);
    }
  }, [state.succeeded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFinalSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(formData);
    handleWhatsAppSubmit();
  };

  const handleWhatsAppSubmit = () => {
    const phoneNumber = '919560844391'; // Dr. Ajay's number without spaces or special characters
    
    // Format the message for WhatsApp
    const message = `Hello Dr. Ajay,\n\nNew appointment request:\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nPreferred Date: ${formData.date}\nQuery: ${formData.message}`;
    
    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);
    
    // Create WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    
    // Open WhatsApp in a new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <section className="appointment relative py-24 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-200 to-gray-400"></div>
      <div className="absolute inset-0 bg-blue-600 opacity-20 transform -skew-y-6"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <h2 className="text-4xl font-bold mb-6 text-white">Book Your Appointment</h2>
            <p className="text-xl text-white mb-8">
              Schedule a consultation with Dr. Ajay Aggarwal today.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <FeatureCard
                icon={<FaUserMd className="text-blue-900" />}
                title="Expert Care"
                description="Consult with a leading endocrinologist"
              />
              <FeatureCard
                icon={<FaCalendarAlt className="text-blue-900" />}
                title="Flexible Scheduling"
                description="Choose a time that suits you best"
              />
              <FeatureCard
                icon={<FaClinicMedical className="text-blue-900" />}
                title="State-of-the-art Facility"
                description="Visit our modern clinic at Sir Ganga Ram Hospital"
              />
            </div>
          </div>
          <div className="lg:w-1/2 bg-white rounded-lg shadow-2xl p-8">
            <div className="relative w-full h-[400px] flex items-center justify-center" >
              {state.succeeded ? (
                <>
                  <img
                    src="/images/thankyou.png"
                    alt="Thank you"
                    className="transition-opacity duration-300 ease-in-out 
                      w-full h-full object-contain 
                      px-4 sm:px-8 md:px-12 lg:px-16
                      max-w-[280px] sm:max-w-[320px] md:max-w-[360px] lg:max-w-[400px]"
                  />
                  {!imageLoaded && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-900"></div>
                    </div>
                  )}
                </>
              ) : (
                <form onSubmit={handleFinalSubmit} className="space-y-4">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Full Name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="date"
                    name="date"
                    id='date'
                    value={formData.date}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ValidationError prefix="Date" field="date" errors={state.errors} />
                  <textarea
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Information Query"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  ></textarea>
                  <div className="flex gap-4">
                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="flex-1 bg-blue-900 text-white font-semibold py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
                    >
                      {state.submitting ? 'Submitting....' : 'Book Appointment'}
                    </button>
                  
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-blue-400 rounded-full opacity-20 transform translate-x-1/2 translate-y-1/2"></div>
    </section>
  );
};

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
  <div className="flex items-center space-x-4">
    <div className="text-4xl">{icon}</div>
    <div>
      <h3 className="text-xl font-semibold text-white">{title}</h3>
      <p className="text-blue-100">{description}</p>
    </div>
  </div>
);

export default BookAppointment;
