import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';

const Bone: React.FC = () => {
  const boneInfo = {
    title: "Osteoporosis and Bone Health",
    introduction: "Maintaining strong bones is crucial for overall health and quality of life. Our bone health services focus on preventing, diagnosing, and treating osteoporosis and other bone-related conditions.",
    imageSrc: "/images/osteoporosis-bone.jpg",
    sections: [
      {
        title: "Bone Health Concerns",
        content: [
          "Osteoporosis",
          "Osteopenia",
          "Vitamin D deficiency",
          "Calcium metabolism disorders",
          "Secondary causes of bone loss"
        ]
      },
      {
        title: "Risk Factors and Symptoms",
        content: [
          "Age (especially post-menopausal women and older men)",
          "Family history of osteoporosis",
          "Low body weight or small frame",
          "Smoking and excessive alcohol consumption",
          "Lack of exercise",
          "Certain medications",
          "Height loss or stooped posture",
          "Bone fractures from minor falls"
        ]
      },
      {
        title: "Diagnosis and Treatment",
        content: [
          "Bone density scans (DXA)",
          "Blood and urine tests for bone markers",
          "Comprehensive fracture risk assessment",
          "Calcium and vitamin D supplementation",
          "Prescription medications to increase bone density",
          "Weight-bearing and resistance exercises",
          "Fall prevention strategies"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Early screening and risk assessment",
          "Personalized treatment plans",
          "Regular monitoring of bone health",
          "Patient education on bone-healthy lifestyle",
          "Coordination with orthopedics and physical therapy",
          "Access to clinical trials for new osteoporosis treatments",
          "Holistic approach addressing nutrition, exercise, and medication"
        ]
      }
    ]
  };

  return <ServiceArticle {...boneInfo} />;
};

export default Bone;
