import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface ServiceCardProps {
  title: string;
  description: string;
  imageSrc: string;
  path: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, imageSrc, path }) => {
  return (
    <Link to={path} className="block">
      <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300">
        <img src={imageSrc} alt={title} className="w-full h-48 object-cover mb-4 rounded" />
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </Link>
  );
};

const services = [
  {
    title: 'Diabetes Management',
    description: 'Comprehensive management of diabetes using state-of-the-art treatments and personalized care plans to help patients achieve stable blood sugar levels and prevent complications.',
    imageSrc: '/images/diabetes-management.jpg',
    path: '/services/diabetes-management'
  },
  {
    title: 'Thyroid Disorders',
    description: 'Expert diagnosis and management of conditions such as hypothyroidism, hyperthyroidism, thyroid nodules, and thyroid cancer, ensuring tailored treatment strategies for optimal thyroid function.',
    imageSrc: '/images/thyroid-disorders.jpg',
    path: '/services/thyroid-disorders'
  },
  {
    title: 'Hormonal Imbalances',
    description: 'Specialized care for conditions such as adrenal disorders, pituitary gland dysfunction, and hormonal imbalances affecting reproductive health, utilizing advanced hormonal testing and individualized therapies.',
    imageSrc: '/images/hormonal-imbalances.jpg',
    path: '/services/hormonal-imbalance'
  },
  {
    title: 'Growth and Development Issues',
    description: 'Specialized care for growth disorders in children and adolescents, including short stature and delayed puberty, employing a compassionate approach to support healthy growth and development.',
    imageSrc: '/images/growth-development.jpg',
    path: '/services/growth-development'
  },
  {
    title: 'Osteoporosis and Bone Health',
    description: 'Expertise in osteoporosis management and bone density evaluation, emphasizing preventive measures and personalized treatment plans to maintain bone strength and reduce fracture risk.',
    imageSrc: '/images/osteoporosis-bone.jpg',
    path: '/services/osteoporosis-bone'
  },
  {
    title: 'Infertility and Sexual Health',
    description: 'Comprehensive evaluation and management of reproductive disorders, including infertility, polycystic ovary syndrome (PCOS), and disorders affecting sexual health, ensuring compassionate care and effective treatment options.',
    imageSrc: '/images/infertility-sexual-health.jpg',
    path: '/services/infertility-sexual-health'
  },
];

const Services: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  },[]) 
  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h1 className="text-3xl font-bold mb-8 text-center">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  );
};

export default Services;