import React from 'react';
import ServiceArticle from '../components/Service/ServiceArticle';

const Infertility: React.FC = () => {
  const infertilityInfo = {
    title: "Infertility and Sexual Health",
    introduction: "Infertility and sexual health issues can be emotionally challenging. Our compassionate care focuses on identifying the root causes and providing effective treatments to improve reproductive health and overall well-being.",
    imageSrc: "/images/infertility-sexual-health.jpg",
    sections: [
      {
        title: "Common Infertility and Sexual Health Issues",
        content: [
          "Female infertility (e.g., ovulation disorders, PCOS)",
          "Male infertility (e.g., low sperm count, motility issues)",
          "Hormonal imbalances affecting fertility",
          "Erectile dysfunction",
          "Low libido",
          "Menstrual disorders"
        ]
      },
      {
        title: "Diagnosis",
        content: [
          "Comprehensive medical history and physical examination",
          "Hormonal blood tests",
          "Semen analysis",
          "Ovulation testing",
          "Imaging studies (ultrasound, hysterosalpingogram)",
          "Genetic testing when appropriate"
        ]
      },
      {
        title: "Treatment Options",
        content: [
          "Fertility medications to stimulate ovulation",
          "Hormonal treatments for various reproductive issues",
          "Assisted reproductive technologies (e.g., IUI, IVF)",
          "Lifestyle modifications to improve fertility",
          "Medications for erectile dysfunction",
          "Hormone replacement therapy for sexual health issues",
          "Psychological support and counseling"
        ]
      },
      {
        title: "Our Approach",
        content: [
          "Personalized fertility and sexual health assessments",
          "Collaborative care with reproductive endocrinologists",
          "Comprehensive treatment plans addressing both partners",
          "Patient education on reproductive health",
          "Emotional support throughout the treatment process",
          "Access to the latest fertility treatments and technologies",
          "Holistic approach considering lifestyle, nutrition, and stress management"
        ]
      }
    ]
  };

  return <ServiceArticle {...infertilityInfo} />;
};

export default Infertility;