// Footer.tsx
import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import '../../index.css';
import {motion} from 'framer-motion';

const Footer: React.FC = () => {
  return (
    
    <footer className="footer bg-[#00043b] py-12 relative text-base md:text-lg leading-relaxed tracking-wide">
      <motion.div className="container mx-auto px-4">
        <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <motion.div className="text-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-white">Dr. Ajay Aggarwal</h3>
            <p className="mb-2">Chairperson and Senior Endocrinologist</p>
            <p className="mb-4">Sir Ganga Ram Hospital, Delhi</p>
            <motion.div className="flex space-x-4">
              <a href="/#" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FaFacebookF size={20} />
              </a>
              <a href="/#" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FaTwitter size={20} />
              </a>
              <a href="/#" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FaLinkedinIn size={20} />
              </a>
            </motion.div>
          </motion.div>
          <motion.div className="text-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-white">Contact</h3>
            <p className="flex items-center mb-3">
              <FaMapMarkerAlt className="mr-3 text-blue-400" />
              22/2, Maithile Sharan Marg, Block 22, Shakti Nagar, Delhi, 110007
            </p>
            <p className="flex items-center mb-3">
              <FaPhone className="mr-3 text-blue-400" />
              09560844391
            </p>
            <p className="flex items-center mb-3">
              <FaEnvelope className="mr-3 text-blue-400" />
              [Hospital Email]
            </p>
          </motion.div>
          <motion.div className="text-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-white">Qualifications</h3>
            <ul className="list-disc list-inside">
              <li className="mb-2">MBBS, MD (Medicine)</li>
              <li className="mb-2">DM (Endocrinology)</li>
              <li className="mb-2">Member of Endocrine Society (USA)</li>
            </ul>
          </motion.div>
        </motion.div>
        <motion.div className="mt-12 pt-8 border-t border-gray-700 text-center text-gray-400">
          <p className="mb-4">&copy; {new Date().getFullYear()} Dr. Ajay Aggarwal. All rights reserved.</p>
          <p className="text-sm">
            Created by <a href="https://rnahealthtech.com" className="text-white-800 hover:underline">RNA HealthTech</a>
                💗
          </p>
        </motion.div>
      </motion.div>
    </footer>
  
  );
};

export default Footer;