import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

interface AnimatedComponentProps {
  children: React.ReactNode;
}

const AnimatedComponent: React.FC<AnimatedComponentProps> = ({ children }) => {
  
  const controls = useAnimation();
  
  const ref = useRef(null);
  const inView = useInView(ref, {
    once:true, 
    amount: 0.1, 
  });

  useEffect(() => {
    if (inView){
      controls.start({opacity: 1, y:0});
    } 
  },[controls, inView]);


  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedComponent;