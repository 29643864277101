import React, {useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Testimonials from './pages/Testimonials';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Thyroid from './pages/Tyroid';
import Diabetes from './pages/Diabetes';
import Hormonal from './pages/Hormonal';
import Growth from './pages/Growth';
import Bone from './pages/Bone';
import Infertility from './pages/Infertility';
import BlogRouter from './components/Blog/BlogRouter';
import AnimateComponent from './AnimateComponent';

import ReactGA from 'react-ga';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-KZT3PEE729');
    //eslint-disable-next-line
  },[]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  },[location]);

  return (
      <Layout>
        <Routes>
          <Route path="/" element={<AnimateComponent><Home /></AnimateComponent>} />
          <Route path="/about" element={<AnimateComponent><About /></AnimateComponent>} />
          <Route path="/services" element={<AnimateComponent><Services /></AnimateComponent>} />
          <Route path="/testimonials" element={<AnimateComponent><Testimonials /></AnimateComponent>} />
          <Route path="/contact" element={<AnimateComponent><Contact /></AnimateComponent>} />
          <Route path="/blog" element={<AnimateComponent><Blog /></AnimateComponent>}/>
          <Route path="/blog/:slug" element={<BlogRouter />}/>
          <Route path="/services/thyroid-disorders" element={<Thyroid />} />
          <Route path="/services/diabetes-management" element={<Diabetes />} />
          <Route path="/services/hormonal-imbalance" element={<Hormonal />} />
          <Route path="/services/growth-development" element={<Growth />} />
          <Route path="/services/osteoporosis-bone" element={<Bone />}/>
          <Route path="/services/infertility-sexual-health" element={<Infertility />} />
        </Routes>
      </Layout>
  );
};

export default App;