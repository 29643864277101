import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ServiceCard } from '../../pages/Services';

const HomeServices: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState<number | 0>(0);

    const featuredServices = [
        {
            title: 'Diabetes Management',
            description: 'State-of-the-art treatments and personalized care plans for stable blood sugar levels.',
            imageSrc: '/images/diabetes-management.jpg',
            path: '/services/diabetes-management'
        },
        {
            title: 'Thyroid Disorders',
            description: 'Expert diagnosis and management of various thyroid conditions for optimal function.',
            imageSrc: '/images/thyroid-disorders.jpg',
            path: '/services/thyroid-disorders'
        },
        {
            title: 'Hormonal Imbalances',
            description: 'Specialized care using advanced hormonal testing and individualized therapies.',
            imageSrc: '/images/hormonal-imbalances.jpg',
            path: '/services/hormonal-imbalance'
        },
        {
            title: 'Osteoporosis and Bone Health',
            description: 'Comprehensive care for maintaining bone strength and reducing fracture risk.',
            imageSrc: '/images/osteoporosis-bone.jpg',
            path: '/services/osteoporosis-bone'
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % featuredServices.length);
        }, 2500); // Change slide every 2.5 seconds

        return () => clearInterval(timer);
    // eslint-disable-next-line
    }, []);

    return (
        <section className="bg-gradient-to-br from-blue-50 to-white py-24 relative overflow-hidden">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-900">Our Specialized Services</h2>
                <div className="max-w-3xl mx-auto text-center mb-16">
                    <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">Delhi's Top-Ranked Endocrinologist</h3>
                    <p className="text-gray-600 leading-relaxed">
                        Dr. Ajay Aggarwal is recognized as one of Delhi's leading endocrinologists, providing exceptional care for a wide range of endocrine disorders. With cutting-edge treatments and a patient-centered approach, we offer specialized services tailored to your unique health needs. Experience the difference of world-class endocrine care right here in Delhi.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <div className="hidden lg:block lg:w-1/3 lg:pr-12">
                        <div className="relative">
                            <div className="absolute inset-0 bg-blue-200 rounded-full opacity-20 filter blur-3xl transform -rotate-6"></div>
                            <img
                                src="/images/atwork.jpg"
                                alt="Endocrinologist at work"
                                className="relative z-10 w-full h-auto rounded-lg shadow-2xl transform hover:scale-105 transition-transform duration-300"
                            />
                        </div>
                        <div className="mt-4 text-center">
                            <p className="text-sm text-gray-600 italic">Dr. Ajay Aggarwal at work</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/4">
                        {/* Desktop view - unchanged */}
                        <div className="hidden lg:grid lg:grid-cols-2 lg:gap-6">
                            {featuredServices.map((service, index) => (
                                <div
                                    key={index}
                                    className={`
                                        relative z-${10 + index} 
                                        lg:transform-none lg:hover:scale-105 
                                        transition-all duration-300 mb-8 lg:mb-0
                                        ${index % 2 === 0 ? 'lg:even:mt-4' : 'lg:odd:mt-4'}
                                    `}
                                >
                                    <div
                                        className={`
                                            bg-white rounded-lg shadow-xl overflow-hidden 
                                            outline outline-2 outline-blue-200 hover:outline-blue-400 
                                            transition-all duration-300
                                            transform lg:transform-none
                                            ${index % 2 === 0 ? '-rotate-3 hover:rotate-0' : 'rotate-3 hover:rotate-0'}
                                        `}
                                    >
                                        <ServiceCard {...service} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Mobile carousel */}
                        <div className="lg:hidden relative">
                            <div className="overflow-hidden">
                                <div
                                    className="flex transition-transform duration-500 ease-in-out"
                                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                                >
                                    {featuredServices.map((service, index) => (
                                        <div key={index} className="w-full flex-shrink-0 px-4">
                                            <div className={`
                                                bg-gradient-to-br from-blue-100 to-white
                                                rounded-lg shadow-xl overflow-hidden 
                                                transition-all duration-300
                                            `}>
                                                <ServiceCard {...service} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 flex justify-center space-x-2 mt-4">
                                {featuredServices.map((_, index) => (
                                    <button
                                        key={index}
                                        className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-blue-600' : 'bg-blue-200'
                                            }`}
                                        onClick={() => setCurrentSlide(index)}
                                    ></button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-16">
                    <Link
                        to="/services"
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-950 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
                    >
                        Explore All Services
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 ml-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
            <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-blue-100 rounded-tl-full opacity-30 transform translate-x-1/4 translate-y-1/4"></div>
        </section>
    );
};

export default HomeServices;
