import React from 'react';

interface SchemaMarkupProps {
  type: 'Doctor' | 'MedicalBusiness' | 'WebPage';
  pageName?: string;
  pageDescription?: string;
}

export const SchemaMarkup: React.FC<SchemaMarkupProps> = ({ type, pageName, pageDescription }) => {
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": type,
  };

  const doctorSchema = {
    ...baseSchema,
    "@type": "Physician",
    "@id": "https://www.drajayaggarwal.in/#doctor",
    name: "Dr. Ajay Aggarwal",
    honorificPrefix: "Dr.",
    image: {
      "@type": "ImageObject",
      url: "https://www.drajayaggarwal.in/dr-ajay.jpg",
      width: "800",
      height: "600"
    },
    url: "https://www.drajayaggarwal.in",
    telephone: "+91-9560844391",
    email: "mailto:contact@drajayaggarwal.in",
    description: "Leading endocrinologist in Delhi specializing in diabetes, thyroid disorders, and hormonal imbalances.",
    medicalSpecialty: [
      {
        "@type": "MedicalSpecialty",
        name: "Endocrinology"
      },
      {
        "@type": "MedicalSpecialty",
        name: "Diabetology"
      },
      {
        "@type": "MedicalSpecialty",
        name: "Thyroidology"
      }
    ],
    hasCredential: [
      {
        "@type": "EducationalOccupationalCredential",
        credentialCategory: "degree",
        name: "MD"
      },
      {
        "@type": "EducationalOccupationalCredential",
        credentialCategory: "degree",
        name: "DM (Endocrinology)"
      }
    ],
    memberOf: {
      "@type": "MedicalOrganization",
      name: "Sir Ganga Ram Hospital",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Sir Ganga Ram Hospital Marg",
        addressLocality: "Delhi",
        addressRegion: "Delhi",
        postalCode: "110060",
        addressCountry: "IN"
      }
    },
    availableService: [
      {
        "@type": "MedicalTherapy",
        name: "Diabetes Management",
        description: "Comprehensive diabetes care including type 1, type 2, and gestational diabetes"
      },
      {
        "@type": "MedicalTherapy",
        name: "Thyroid Treatment",
        description: "Diagnosis and treatment of hypothyroidism, hyperthyroidism, and other thyroid disorders"
      },
      {
        "@type": "MedicalTherapy",
        name: "Hormone Therapy",
        description: "Treatment for various hormonal imbalances and endocrine disorders"
      }
    ]
  };

  const webPageSchema = {
    ...baseSchema,
    "@type": "WebPage",
    name: pageName || "Dr. Ajay Aggarwal - Leading Endocrinologist in Delhi",
    description: pageDescription || "Dr. Ajay Aggarwal is a renowned endocrinologist in Delhi, specializing in diabetes, thyroid disorders, and hormonal imbalances.",
    url: "https://www.drajayaggarwal.in",
    isPartOf: {
      "@type": "WebSite",
      name: "Dr. Ajay Aggarwal",
      url: "https://www.drajayaggarwal.in"
    }
  };

  const getSchemaByType = () => {
    switch (type) {
      case 'Doctor':
        return doctorSchema;
      case 'WebPage':
        return webPageSchema;
      default:
        return doctorSchema;
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(getSchemaByType())
      }}
    />
  );
};