// src/components/BlogRouter.tsx

import React from 'react';
import { useParams } from 'react-router-dom';
import EndocrinologyBlog from '../../blog/EndocrinologyBlog';
import DiabetesManagementBlog from '../../blog/DiabetesBlog';
import HormonesMentalHealthBlog from '../../blog/HormonesMentalHealth';

const BlogRouter: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  switch (slug) {
    case 'understanding-endocrinology':
      return <EndocrinologyBlog />;
    case 'diabetes-management-advances':
      return <DiabetesManagementBlog />;
    case 'hormones-mental-health':
      return <HormonesMentalHealthBlog />;
    default:
      return <div>Blog post not found</div>;
  }
};

export default BlogRouter;