import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {
  
  useEffect(() => {
     window.scrollTo(0,0) 
    //eslint disable next line
    },[])
  const education = [
    'MBBS from Punjab University, Patiala',
    'MD in Medicine from Punjab University, Chandigarh',
    'DM in Endocrinology from Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow'
  ];
  const hospitals = [
    'Diabetes, Thyroid & Hormone Centre (Clinic)',
    'Sir Ganga Ram Hospital, Delhi (Current)',
    'Dayanand Medical College, Ludhiana',
    'Fortis Escorts Heart Institution'
  ];

  const interestAreas = [
    {
      title: 'Infertility',
      description: 'Dr. Aggarwal is a leading expert in treating infertility related to hormonal imbalances. He employs advanced diagnostic techniques and personalized treatment plans to help couples achieve their dream of parenthood.'
    },
    {
      title: 'Diabetes',
      description: 'With extensive experience in diabetes management, Dr. Aggarwal offers comprehensive care for both Type 1 and Type 2 diabetes. He focuses on lifestyle modifications, cutting-edge medications, and continuous glucose monitoring to optimize blood sugar control.'
    },
    {
      title: 'Thyroid Disorders',
      description: 'Dr. Aggarwal specializes in diagnosing and treating a wide range of thyroid disorders, including hypothyroidism, hyperthyroidism, and thyroid nodules. He utilizes state-of-the-art imaging and laboratory tests to ensure accurate diagnosis and tailored treatment.'
    },
    {
      title: 'Growth Disorders',
      description: 'As a pediatric endocrinology expert, Dr. Aggarwal provides specialized care for children with growth disorders. He offers thorough evaluations and personalized treatment plans to help children achieve their full growth potential.'
    },
    {
      title: 'Hormonal Disorders',
      description: 'Dr. Aggarwal has extensive experience in treating various hormonal disorders, including PCOS, adrenal disorders, and pituitary conditions. He employs a holistic approach, combining medical treatments with lifestyle recommendations for optimal hormonal balance.'
    }
  ];

  return (
      <div className="min-h-screen relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-rose-50 via-white to-sky-100 -z-10"></div>
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;charset=utf-8,...')] opacity-20 -z-10"></div>

        <Helmet>
          <title>Dr. Ajay Aggarwal - Top Endocrinologist in Delhi | 25 Years of Experience</title>
          <meta name="description" content="Dr. Ajay Aggarwal is a leading endocrinologist in Delhi with 25 years of experience. Specializing in infertility, diabetes, thyroid disorders, and hormonal imbalances." />
          <meta name="keywords" content="Dr. Ajay Aggarwal, Endocrinologist, Delhi, Infertility, Diabetes, Thyroid, Hormonal Disorders" />
        </Helmet>

        <div className="relative">
          <div className="h-96 bg-cover bg-center" style={{ backgroundImage: 'url("/images/man2.jpg")' }}>
            <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-60"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <h1 className="text-5xl font-bold text-white text-center shadow-text">Meet Dr. Ajay Aggarwal</h1>
            </div>
          </div>

          <div className="container mx-auto px-4 mt-12">
            <div className="bg-white shadow-xl rounded-lg overflow-hidden mb-12 -mt-32 relative z-10 transition-all duration-300 hover:shadow-2xl">
              <div className="md:flex">
                <div className="md:w-1/3">
                  <img
                    src="/images/hero-home.png"
                    alt="Dr. Ajay Aggarwal"
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                  />
                </div>
                <div className="md:w-2/3 p-8">
                  <h2 className="text-4xl font-bold mb-4 mt-24 text-gray-900">Dr. Ajay Aggarwal</h2>
                  <p className="text-2xl mb-4 text-gray-700">Endocrinologist</p>
                  <p className="text-xl mb-6 text-gray-600">
                    Total Experience: <span className="text-2xl font-bold text-gray-600">25 years</span>
                  </p>
                  <p className="text-lg text-gray-600 leading-relaxed">
                    Dr. Ajay Aggarwal is a renowned endocrinologist with a wealth of experience in treating hormonal disorders. His patient-centric approach and expertise make him one of the top endocrinologists in Delhi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Areas of Expertise</h2>

          <section className="mb-16 bg-gradient-to-br from-white to-blue-50 border-2 border-gray-200 rounded-lg shadow-lg p-8 transition-all duration-300 hover:shadow-xl">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Interest Areas</h2>

            <div className="flex flex-col lg:flex-row items-center gap-12">
              <div className="lg:w-1/3">
                <img
                  src="/images/logo.jpg"
                  alt="Dr. Aggarwal's Interest Areas"
                  className="rounded-lg shadow-md w-full object-cover h-64 lg:h-auto transition-transform duration-300 hover:scale-105"
                />
              </div>

              <div className="lg:w-2/3">
                {interestAreas.map((area, index) => (
                  <details key={index} className="mb-6 group">
                    <summary className="text-2xl font-semibold text-gray-800 cursor-pointer hover:text-blue-600 transition-colors duration-300">
                      {area.title}
                    </summary>
                    <p className="mt-4 text-gray-700 leading-relaxed text-lg pl-6 border-l-4 border-blue-200">
                      {area.description}
                    </p>
                  </details>
                ))}
              </div>
            </div>
          </section>

          
          <section className="mb-16 bg-gradient-to-br from-white to-red-50 rounded-lg shadow-lg p-8 transition-all duration-300 hover:shadow-xl">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Educational Qualifications</h2>
            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/3 mb-6 md:mb-0 md:pl-8">
                <img
                  src="/images/educational-institue.jpg"
                  alt="Dr. Aggarwal's Education"
                  className="rounded-lg shadow-md w-full transition-transform duration-300 hover:scale-105"
                />
              </div>
              <div className="md:w-2/3">
                <ul className="space-y-4">
                  {education.map((edu, index) => (
                    <li key={index} className="text-lg text-gray-700 leading-relaxed">
                      <span className="font-semibold text-gray-800">{edu.split(' from ')[0]}</span>
                      <br />
                      <span className="text-gray-600">from {edu.split(' from ')[1]}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

       
          <section className="mb-16 bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-lg p-8 transition-all duration-300 hover:shadow-xl">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Hospital Affiliations</h2>
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/3 mb-6 md:mb-0">
                <img
                  src="/images/slogan.jpg"
                  alt="Hospitals Dr. Aggarwal is affiliated with"
                  className="rounded-lg shadow-md w-full transition-transform duration-300 hover:scale-105"
                />
              </div>
              <div className="md:w-2/3 md:pl-8">
                <ul className="space-y-4">
                  {hospitals.map((hospital, index) => (
                    <li key={index} className="text-lg text-gray-700 leading-relaxed">
                      <span className="font-semibold text-gray-800">{hospital.split(', ')[0]}</span>
                      <br />
                      <span className="text-gray-600">{hospital.split(', ')[1]}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
            <div className="md:flex">
              <div className="md:w-1/2 p-8">
                <h3 className="text-2xl font-semibold mb-4  mt-24 text-gray-900">Dr. Aggarwal's Approach</h3>
                <p className="text-gray-700 mb-4 leading-relaxed">
                  Dr. Ajay Aggarwal believes in a holistic approach to endocrine disorders. He combines the latest medical advancements with personalized care to ensure the best outcomes for his patients.
                </p>
                <p className="text-gray-700 leading-relaxed">
                  With a focus on patient education and preventive care, Dr. Aggarwal empowers his patients to take control of their hormonal health and lead fulfilling lives.
                </p>
              </div>
              <div className="md:w-1/2">
                <img
                  src="/images/lab.jpg"
                  alt="Endocrinology Lab"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default About;