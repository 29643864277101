// src/pages/EndocrinologyBlog.tsx

import React from 'react';
import { Helmet } from 'react-helmet';

import BlogPost from '../components/Blog/BlogPost';

const EndocrinologyBlog: React.FC = () => {
    const blogContent = {
        title: "Understanding Endocrinology: The Science of Hormones and Their Impact on Health",
        author: "Dr. Ajay Aggarwal",
        date: "2024-07-15",
        content: [
            {
                type: 'paragraph' as const,
                content: "Endocrinology, the study of hormones and their effects on the body, plays a crucial role in modern medicine. This field encompasses a wide range of conditions and processes that affect millions of people worldwide. In this blog post, we'll explore the fundamentals of endocrinology and its significance in maintaining overall health."
            },
            {
                type: 'heading' as const,
                content: "What is Endocrinology?"
            },
            {
                type: 'paragraph' as const,
                content: "Endocrinology focuses on the endocrine system, a network of glands that produce and secrete hormones directly into the bloodstream. These hormones act as chemical messengers, regulating various bodily functions such as metabolism, growth, development, reproduction, and mood."
            },
            {
                type: 'image' as const,
                src: '/images/human-endocrine-system.jpg',
                alt: 'Diagram of the human endocrine system'
            },
            {
                type: 'heading' as const,
                content: "Key Endocrine Glands and Their Functions"
            },
            {
                type: 'list' as const,
                content: [
                    "Pituitary Gland: Often called the 'master gland,' it controls other endocrine glands.",
                    "Thyroid Gland: Regulates metabolism and energy levels.",
                    "Pancreas: Produces insulin and glucagon to control blood sugar levels.",
                    "Adrenal Glands: Manage stress responses and produce sex hormones.",
                    "Ovaries and Testes: Responsible for reproductive functions and sex hormone production."
                ]
            },
            // ... (rest of the content)
        ],
        tags: ['Endocrinology', 'Hormones', 'Health', 'Medical Science', 'Wellness']
    };

    return (
        <>
            <Helmet>
                <title>{blogContent.title}</title>
                <meta name="description" content="Learn about endocrinology, the science of hormones, and its impact on overall health. Explore key endocrine glands, common disorders, and the importance of hormonal balance." />
                <meta name="keywords" content="endocrinology, hormones, endocrine system, hormonal health, medical science" />
                <meta property="og:title" content={blogContent.title} />
                <meta property="og:description" content="Discover the fascinating world of endocrinology and its crucial role in maintaining health and well-being." />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://yourwebsite.com/blog/understanding-endocrinology" />
                <meta property="og:image" content="https://yourwebsite.com/images/endocrine-system.jpg" />
            </Helmet>
            <BlogPost {...blogContent} />
        </>
    );
};

export default EndocrinologyBlog;