import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BlogCard from '../components/Blog/BlogCard';


const blogPosts = [
  {
    title: "Understanding Endocrinology: The Science of Hormones and Their Impact on Health",
    excerpt: "Explore the fundamentals of endocrinology and its significance in maintaining overall health. Learn about key endocrine glands and common disorders.",
    author: "Dr. Jane Smith",
    date: "2024-07-15",
    imageUrl: "/images/human-endocrine-system.jpg",
    slug: "understanding-endocrinology",
    tags: ['Endocrinology', 'Hormones', 'Health']
  },
  {
    title: "Diabetes Management: Latest Advances and Treatment Options",
    excerpt: "Discover the newest approaches to diabetes management, including cutting-edge technologies and lifestyle interventions.",
    author: "Dr. Ajay Aggarwal",
    date: "2024-07-10",
    imageUrl: "/images/diabetes-management2.jpg",
    slug: "diabetes-management-advances",
    tags: ['Diabetes', 'Treatment', 'Technology']
  },
  {
    title: "The Role of Hormones in Mental Health",
    excerpt: "Uncover the intricate relationship between hormones and mental health, and how hormonal imbalances can affect mood and cognition.",
    author: "Dr. Ajay Aggarwal",
    date: "2024-07-05",
    imageUrl: "/images/hormones-mental-health.jpg",
    slug: "hormones-mental-health",
    tags: ['Mental Health', 'Hormones', 'Psychology']
  },
  // Add more blog posts as needed
];

const Blog: React.FC = () => {
  
  useEffect(() => {
    window.scrollTo(0,0);
  },[])
  return (
    <>
      <Helmet>
        <title>Our Blog - Latest Articles on Endocrinology and Health</title>
        <meta name="description" content="Explore our collection of articles on endocrinology, hormones, and overall health. Stay informed with the latest research and medical insights." />
        <meta name="keywords" content="blog, endocrinology, health, medical articles, hormones" />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 mt-20 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-8">Our Blog & Latest Research</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <BlogCard key={index} {...post} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;