// src/components/ServiceArticle.tsx

import React, { useEffect, useState } from 'react';

interface Section {
  title: string;
  content: string[];
}

interface ServiceArticleProps {
  title: string;
  introduction: string;
  imageSrc: string;
  sections: Section[];
}

const ServiceArticle: React.FC<ServiceArticleProps> = ({ title, introduction, imageSrc, sections }) => {
  const [activeSection, setActiveSection] = useState<number | null>(null);
  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  return (
    <article className="container mx-auto px-4 py-12 max-w-4xl mt-20">
      <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">{title}</h1>
      <div className="relative h-96 mb-8">
        <img src={imageSrc} alt="" className="w-full h-full object-cover rounded-lg shadow-lg" />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50 rounded-lg"></div>
        <p className="absolute bottom-6 left-6 right-6 text-white text-xl leading-relaxed">{introduction}</p>
      </div>
      {sections.map((section, index) => (
        <section key={index} className="mb-8 bg-white rounded-lg shadow-md overflow-hidden">
          <button
            className="w-full text-left px-6 py-4 bg-blue-100 hover:bg-blue-200 transition-colors duration-200"
            onClick={() => setActiveSection(activeSection === index ? null : index)}
          >
            <h2 className="text-2xl font-semibold text-blue-800 flex justify-between items-center">
              {section.title}
              <svg
                className={`w-6 h-6 transform transition-transform duration-200 ${activeSection === index ? 'rotate-180' : ''}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </h2>
          </button>
          {activeSection === index && (
            <div className="px-6 py-4 bg-blue-50">
              {section.content.map((paragraph, pIndex) => (
                <p key={pIndex} className="mb-3 text-gray-700 leading-relaxed">{paragraph}</p>
              ))}
            </div>
          )}
        </section>
      ))}
    </article>
  );
};

export default ServiceArticle;